frappe.provide("italjapan_erpnext.utils");

italjapan_erpnext.utils = {
    // Uses ITJ Additional Discount Rules.
    // Required on client side to reflect discount amount accurately after changing items/qtys in a session.
    // REST API does not depend on this.
	// calculate_additional_discount: async function (frm) {
    //     // Execute code only if additional_discount_percentage field exists in the doctype
    //     const discount_fieldname = "discount_amount";
	// 	if (!discount_fieldname in frm.fields_dict) return

	// 	let additional_discount_amount = await frappe.call({
	// 		method: "italjapan_erpnext.itj_utils.calculate_additional_discount",
	// 		args: { doc: frm.doc }
	// 	})
	// 	console.log("discount amount", additional_discount_amount.message);

	// 	frm.set_value("apply_discount_on", "Net Total");
	// 	frm.set_value(discount_fieldname, additional_discount_amount.message);
	// },
	ping: function() {
		console.log("pong");
	},
	//If company abbr is ITJ, there must exist a naming series ITJ-SINV- or ITJ/SINV in Naming Series form.
	set_naming_series_by_company : async function(frm) {

		if (!frm.doc.company) return

		var r_abbr = await frappe.db.get_value("Company", frm.doc.company, "abbr");
		var abbr = r_abbr.message.abbr;

		italjapan_erpnext.utils.set_naming_series_by_pattern(frm, abbr);


	},

	set_naming_series_by_pattern: function(frm, pattern, exact_match) {
		if (!frm.doc.__islocal) return

		var naming_series = frm.fields_dict.naming_series.df.options.split("\n").find(
			(item) => {
				if (exact_match) {
					return item == pattern;
				} else {
					return item.indexOf(pattern) != -1;
				}
			}
		)
		if (!naming_series) {
			frappe.show_alert(__("<span class='indicator red'>No naming series containing " + pattern + "</span>"))
		}
		frm.set_value("naming_series", naming_series);
	},
	itj_gross_profit_column_total: function(values, column, type) {
		if (frappe.boot.versions.frappe.split(".")[0] != "11"){
			column.column.fieldname = column.column.label;
		}
		
		if (values.length > 0) {
			if (column.column.fieldname == __("Qty")) {
				return values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Selling Amount")) {
				frappe.query_report.itj_total_selling_amount = values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Selling Amount with Returns")) {
				frappe.query_report.itj_total_selling_amount_with_returns = values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Selling Amount %")) {
				return values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Buying Amount")) {
				frappe.query_report.itj_total_buying_amount = values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Buying Amount ITJ")) {
				frappe.query_report.tl_total_buying_amount_from_itj = values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Gross Profit")) {
				frappe.query_report.itj_total_gross_profit = values.reduce((a, b) => a + flt(b));
			} else if ([__("Avg. Selling Rate"), __("Avg. Valuation Rate")].indexOf(column.column.fieldname) != -1) {
				return values.reduce((a, b) => a + flt(b)) / values.length;
			} else if (column.column.fieldname == __("Avg. Gross Profit %")) {
				if(frappe.query_report.itj_total_selling_amount)
					return (frappe.query_report.itj_total_gross_profit /  frappe.query_report.itj_total_selling_amount ) * 100
				else
					return 0
			} else if (column.column.fieldname == __("Avg. Gross Margin %")) {
				if(frappe.query_report.itj_total_buying_amount)
					return (frappe.query_report.itj_total_gross_profit /  frappe.query_report.itj_total_buying_amount ) * 100
				else
					return 0
			} else if (column.column.fieldname == __("Group Gross Profit")) {
				frappe.query_report.itj_total_group_gross_profit = values.reduce((a, b) => a + flt(b));
			} else if (column.column.fieldname == __("Avg. Group Gross Profit %")) {
				if(frappe.query_report.itj_total_selling_amount)
					return (frappe.query_report.itj_total_group_gross_profit /  frappe.query_report.itj_total_selling_amount ) * 100
				else
					return 0
			} else if (column.column.fieldname == __("Avg. Group Gross Margin %")) {
				if(frappe.query_report.tl_total_buying_amount_from_itj){
					return (frappe.query_report.itj_total_group_gross_profit /  frappe.query_report.tl_total_buying_amount_from_itj ) * 100
				} else if(frappe.query_report.itj_total_buying_amount)
					return (frappe.query_report.itj_total_group_gross_profit /  frappe.query_report.itj_total_buying_amount ) * 100
				else
					return 0
			} else if (column.column.fieldname == __("Shipping Amount") ) {
				frappe.query_report.itj_total_shipping_amount = values.reduce((a,b) => a + flt(b));
			} else if (column.column.fieldname == __("Shipping Cost") ) {
				frappe.query_report.itj_total_shipping_cost = values.reduce((a,b) => a + flt(b));
			} else if (column.column.fieldname == __("Total Commission") ) {
				frappe.query_report.itj_total_commission = values.reduce((a,b) => a + flt(b));
			} else if (column.column.fieldname == __("Forwarder Commission") ) {
				frappe.query_report.itj_total_commission = values.reduce((a,b) => a + flt(b)); 
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	itj_gestino_lino_column_total: function(values, column, type){

		if (values.length > 0) {

			if (column.column.fieldname == "total_available_qty") {  //DISPONIBLITA’ TOTALE
				return values.reduce((a, b) => a + b);
			}
			if (column.column.fieldname == "last_purchase_rate") {  //
				return "";
			}

			if (column.column.fieldname == "balance_qty_itj") { 
				frappe.query_report.itj_total_valore = values.slice();
			}
			if (column.column.fieldname == "valuation_rate_itj" && frappe.query_report.itj_total_valore) { 
				let sum = 0;
				for(let i=0; i< values.length; i++) {
					sum += values[i]*frappe.query_report.itj_total_valore[i];  //total is sum for all(balance_qty_itj * valuation_rate_itj)
				}
				return sum;
			}

			if (column.column.fieldname == "balance_qty_aion") { 
				frappe.query_report.aion_total_valore = values.slice();
			}
			if (column.column.fieldname == "valuation_rate_aion" && frappe.query_report.aion_total_valore) { 
				let sum = 0;
				for(let i=0; i< values.length; i++) {
					sum += values[i]*frappe.query_report.aion_total_valore[i];  //total is sum for all(balance_qty_aion * valuation_rate_aion)
				}
				return sum;
			}

		}
	},
	itj_stock_balance_column_total: function(values, column, type) {
		if (values.length > 0) {
			if ([__("Opening Qty"), __("Opening Value"),
					__("In Qty"), __("In Value"),
					__("Out Qty"), __("Out Value"),
					__("Balance Qty"), __("Balance Value"),
					__("Ordered Qty"), __("Waiting Qty"), __("Proforma Qty"), __("Available Qty"), __("Available Value")].indexOf(column.column.name) != -1) {
				return values.reduce((a, b) => a + flt(b));
			} else {
				return "";
			}
		} else {
			return "";
		}
	},
	itj_venduto_brand_column_total: function(values, column, type){
		if (values.length > 0){
			if(column.column.fieldname == "available_qty"){
				return values.reduce((a, b) => a + flt(b)).toFixed(2);
			}
		}
	},
	update_standard_rate_and_projected_qty: function (frm) {
		if (frm.doc.customer && frm.doc.company && (frm.doc.items.length > 0 && frm.doc.items[0].item_code != "")) {
			frappe.call({
				method: "italjapan_erpnext.itj_utils.update_selling_rate_and_projected_qty",
				args: { document: frm.doc }
			}).done((r) => {
				frappe.model.sync(r.message);
				refresh_field("items");
			});
		};
	},

	get_customer_group: async function(company){
		let value = await frappe.db.get_value("Company", {name: `${company}` }, "itj_registro_excluded_customer_group" )
		if(value.message){
			return value.message.itj_registro_excluded_customer_group
		}

	},

	get_information_for_report:async function(report_name){
		//method to generate specific note name from report_name
		let scrubed_report_name = frappe.scrub(report_name).toUpperCase().replace("-", "_");

		let notes = await frappe.db.get_list('Note', {'limit': 100});
		for(let i of notes){
			if(i['name'].includes(scrubed_report_name) && i['name'].includes('KB') && i['name'].includes('RPT') ){
				window.open(`#Form/Note/${i['name']}`, '_blank')
				return
			}
		}
		frappe.msgprint(__("No Note found for this report."))
	},

	get_information_for_doctype:async function(doctype_name){
		//method to generate specific note name from report_name
		let scrubbed_doctype_name = frappe.scrub(doctype_name).toUpperCase().replace("-", "_");
		let found_doctype_note = await frappe.db.get_value("Note", {"name": ["like", "%DOC%" + scrubbed_doctype_name]},"name")

		if (found_doctype_note) {
			window.open(`#Form/Note/${found_doctype_note.message.name}`, '_blank')
		} else {
			frappe.msgprint(__("No Note found for this report."))
		}
	},

	get_excluded_naming_series: async function(company = undefined){
		let value = await frappe.call({
			method: "italjapan_erpnext.itj_utils.exclude_naming_series_conditions",
			args: { company : company  },
		})

		if(value.message){
			return value.message.toString();
		}
	},

	remove_empty_rows: function(frm) {
		/**
		This function removes empty rows. Note that in this function, a row is considered
		empty if the fields with `in_list_view: 1` are undefined or falsy because that's
		what users also consider to be an empty row
		 */
		const docs = frappe.model.get_all_docs(frm.doc);

		// we should only worry about table data
		const tables = docs.filter(function(d){
			return frappe.model.is_table(d.doctype);
		});

		tables.map(
			function(doc){
				const cells = frappe.meta.docfield_list[doc.doctype] || [];

				const in_list_view_cells = cells.filter(function(df) {
					return cint(df.in_list_view) === 1;
				});

				var is_empty_row = function(cells) {
					for (var i=0; i < cells.length; i++){
						if(locals[doc.doctype][doc.name][cells[i].fieldname]){
							return false;
						}
					}
					return true;
				}

				if (is_empty_row(in_list_view_cells)) {
					frappe.model.clear_doc(doc.doctype, doc.name);
				}
			}
		);
	}
}
