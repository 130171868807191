// get_fields has been overridden for issue 74: to add sender field in the dialog.
// prepare has been overridden as per issue 72: to auto-set email template from the field itj_email_template, if found, in any doctype.
if (frappe.boot.versions.frappe.split(".")[0] == "11"){
	frappe.views.ITJCommunicationComposer = frappe.views.CommunicationComposer.extend({
		get_fields: function() {
			var me = this;
	
			let contactList = [];
			var fields= [
				{label:__("To"), fieldtype:"MultiSelect", reqd: 0, fieldname:"recipients",options:contactList},
				{fieldtype: "Section Break", collapsible: 1, label: __("CC, BCC & Email Template")},
				{label:__("CC"), fieldtype:"MultiSelect", fieldname:"cc",options:contactList},
				{label:__("BCC"), fieldtype:"MultiSelect", fieldname:"bcc",options:contactList},
				{label:__("Email Template"), fieldtype:"Link", options:"Email Template",
					fieldname:"email_template"},
				{fieldtype: "Section Break"},
				{label:__("Subject"), fieldtype:"Data", reqd: 1,
					fieldname:"subject", length:524288},
				{fieldtype: "Section Break"},
				{
					label:__("Message"),
					fieldtype:"Text Editor", reqd: 1,
					fieldname:"content",
					onchange: frappe.utils.debounce(this.save_as_draft.bind(this), 300)
				},
	
				{fieldtype: "Section Break"},
				{fieldtype: "Column Break"},
				{label:__("Send me a copy"), fieldtype:"Check",
					fieldname:"send_me_a_copy", 'default': frappe.boot.user.send_me_a_copy},
				{label:__("Send Read Receipt"), fieldtype:"Check",
					fieldname:"send_read_receipt"},
				{label:__("Attach Document Print"), fieldtype:"Check",
					fieldname:"attach_document_print"},
				{label:__("Select Print Format"), fieldtype:"Select",
					fieldname:"select_print_format"},
				{label:__("Select Languages"), fieldtype:"Select",
					fieldname:"language_sel"},
				{fieldtype: "Column Break"},
				{label:__("Select Attachments"), fieldtype:"HTML",
					fieldname:"select_attachments"}
			];
	
			// add from if user has access to multiple email accounts
			var email_accounts = frappe.boot.email_accounts.filter(function(account, idx){
				return !in_list(["All Accounts", "Sent", "Spam", "Trash"], account.email_account) &&
					account.enable_outgoing
			})
	
			/* issue 74:
				Filter user email ids in the custom boot parameter by the current document's company.
				If found, display the "from" field with these email ids as options.
				Else, implement the default flow.
			*/
			var email_accounts_for_company = frappe.boot.itj_user_emails_with_company.filter(function(account, idx) {
				return account.company == me.doc.company
			});
	
			if (email_accounts_for_company.length) {
				fields = [
					{label: __("From"), fieldtype: "Select", reqd: 1, fieldname: "sender",
						options: email_accounts_for_company.map(function(e) { return e.email_id; }) }
				].concat(fields);
			} else if(frappe.boot.email_accounts && email_accounts.length > 1) {
				fields = [
					{label: __("From"), fieldtype: "Select", reqd: 1, fieldname: "sender",
						options: email_accounts.map(function(e) { return e.email_id; }) }
				].concat(fields);
			}
	
			return fields;
		},
		prepare: function() {
			var me = this;
			this.setup_subject_and_recipients();
			//this.setup_print_language();
			this.setup_print();
			this.setup_attach();
			this.setup_email();
			this.setup_last_edited_communication();
			this.setup_email_template();
			this.set_values();

	
			// this line adds email template to email dialog.
			if (this.doc.itj_email_template) {
				this.dialog.fields_dict["email_template"].set_value(this.doc.itj_email_template);
			}
	
			frappe.db.get_value("Address", me.doc.customer_address, "email_id", function(r) {
				if (r) {
					me.dialog.fields_dict.recipients.set_value(r.email_id)
				} else {
					me.dialog.fields_dict.recipients.set_value(me.recipients || '');
				}
			})
			this.dialog.fields_dict.cc.set_value(this.cc || '');
			this.dialog.fields_dict.bcc.set_value(this.bcc || '');
	
			if(this.dialog.fields_dict.sender) {
				this.dialog.fields_dict.sender.set_value(this.sender || '');
			}
			this.dialog.fields_dict.subject.set_value(this.subject || '');
	
			this.setup_earlier_reply();
		},
	});	
}
else
{

frappe.views.ITJCommunicationComposer = class ITJCommunicationComposer extends frappe.views.CommunicationComposer { 
	get_fields() {
		var me = this;

		let contactList = [];
		var fields= [
			{label:__("To"), fieldtype:"MultiSelect", reqd: 0, fieldname:"recipients",options:contactList},
			{fieldtype: "Section Break", collapsible: 1, label: __("CC, BCC & Email Template")},
			{label:__("CC"), fieldtype:"MultiSelect", fieldname:"cc",options:contactList},
			{label:__("BCC"), fieldtype:"MultiSelect", fieldname:"bcc",options:contactList},
			{label:__("Email Template"), fieldtype:"Link", options:"Email Template",
				fieldname:"email_template"},
			{fieldtype: "Section Break"},
			{label:__("Subject"), fieldtype:"Data", reqd: 1,
				fieldname:"subject", length:524288},
			{fieldtype: "Section Break"},
			{
				label:__("Message"),
				fieldtype:"Text Editor", reqd: 1,
				fieldname:"content",
				onchange: frappe.utils.debounce(this.save_as_draft.bind(this), 300)
			},

			{fieldtype: "Section Break"},
			{fieldtype: "Column Break"},
			{label:__("Send me a copy"), fieldtype:"Check",
				fieldname:"send_me_a_copy", 'default': frappe.boot.user.send_me_a_copy},
			{label:__("Send Read Receipt"), fieldtype:"Check",
				fieldname:"send_read_receipt"},
			{label:__("Attach Document Print"), fieldtype:"Check",
				fieldname:"attach_document_print"},
			{label:__("Select Print Format"), fieldtype:"Select",
				fieldname:"select_print_format"},
			{label:__("Select Languages"), fieldtype:"Select",
				fieldname:"language_sel"},
			{fieldtype: "Column Break"},
			{label:__("Select Attachments"), fieldtype:"HTML",
				fieldname:"select_attachments"}
		];

		// add from if user has access to multiple email accounts
		var email_accounts = frappe.boot.email_accounts.filter(function(account, idx){
			return !in_list(["All Accounts", "Sent", "Spam", "Trash"], account.email_account) &&
				account.enable_outgoing
		})

		/* issue 74:
			Filter user email ids in the custom boot parameter by the current document's company.
			If found, display the "from" field with these email ids as options.
			Else, implement the default flow.
		*/
		var email_accounts_for_company = frappe.boot.itj_user_emails_with_company.filter(function(account, idx) {
			return account.company == me.doc.company
		});

		if (email_accounts_for_company.length) {
			fields = [
				{label: __("From"), fieldtype: "Select", reqd: 1, fieldname: "sender",
					options: email_accounts_for_company.map(function(e) { return e.email_id; }) }
			].concat(fields);
		} else if(frappe.boot.email_accounts && email_accounts.length > 1) {
			fields = [
				{label: __("From"), fieldtype: "Select", reqd: 1, fieldname: "sender",
					options: email_accounts.map(function(e) { return e.email_id; }) }
			].concat(fields);
		}

		return fields;
	}
	prepare() {
		var me = this;
		this.setup_subject_and_recipients();
		this.setup_print_language();
		this.setup_print();
		this.setup_attach();
		this.setup_email();
		this.setup_last_edited_communication();
		this.setup_email_template();

		// this line adds email template to email dialog.
		if (this.doc.itj_email_template) {
			this.dialog.fields_dict["email_template"].set_value(this.doc.itj_email_template);
		}

		frappe.db.get_value("Address", me.doc.customer_address, "email_id", function(r) {
			if (r) {
				me.dialog.fields_dict.recipients.set_value(r.email_id)
			} else {
				me.dialog.fields_dict.recipients.set_value(me.recipients || '');
			}
		})
		this.dialog.fields_dict.cc.set_value(this.cc || '');
		this.dialog.fields_dict.bcc.set_value(this.bcc || '');

		if(this.dialog.fields_dict.sender) {
			this.dialog.fields_dict.sender.set_value(this.sender || '');
		}
		this.dialog.fields_dict.subject.set_value(this.subject || '');

		//this.setup_earlier_reply();
	}
};

}
// TEMPORARILY DISABLED COMMUNICATION COMPOSER;
frappe.views.CommunicationComposer = frappe.views.ITJCommunicationComposer
